import { Icon } from "@components/Icons"
import MyAccountModal from "@components/myAccountModal"
import TranslateWrapper from "@components/TranslateWrapper"
import translate from "@src/i18n"
import { Button } from "antd"
import cx from "classnames"
import React from "react"

import styles from "./style.module.less"

export const SuccessModal = ({
  visible,
  description,
  handleClose,
  buttonText,
  onClick,
}) => {
  const handleClickButton = () => {
    if (onClick) {
      onClick()
      return
    }

    handleClose()
  }

  return (
    <MyAccountModal
      visible={visible}
      handleClose={handleClose}
      classname="successModal"
    >
      <TranslateWrapper>
        <div className={styles.mainContainer}>
          <div className={styles.descriptionContainer}>
            <span className={styles.form_title}>
              {!description ? translate("changeWithSuccess") : description}
            </span>
          </div>

          <Icon name="Success" className={styles.marginTop44} />

          <Button
            type="primary"
            className={styles.button}
            onClick={handleClickButton}
          >
            {buttonText || translate("goToSite")}
          </Button>
        </div>
      </TranslateWrapper>
    </MyAccountModal>
  )
}
