import { CustomModalErrors } from "@components/CustomModalErrors"
import { useStores } from "@hooks/use-stores"
import { errorsAdapter } from "@modules/Registration/SubscriberRegistration/adapters/registration"
import translate from "@src/i18n"
import { Button, Form, Input } from "antd"
import cx from "classnames"
import _get from "lodash/get"
import React, { useState } from "react"
import { useIntl } from "react-intl"

import styles from "./style.module.less"

const ManagePublicEmail = ({
  data,
  handleClose,
  handleGetDataById,
  handleGetDataSub,
  isEmail,
  isName,
  isNickname,
  isDescription,
  setSuccessMessage,
  isSubscriber,
}) => {
  const [completedStyle, setCompletedStyle] = useState("")
  const intl = useIntl()
  const { TextArea } = Input
  const requiredInput = intl.formatMessage({ id: "requiredInput" })
  const emailInputMessage = intl.formatMessage({ id: "emailInputMessage" })
  const errorFullName = intl.formatMessage({ id: "errorFullName" })
  const placeholder = intl.formatMessage({ id: "insertEmail" })
  const placeholderName = intl.formatMessage({ id: "typeNameFull" })
  const placeholderNickname = intl.formatMessage({ id: "typeNickname" })

  let a = translate("tooltipMessage")
  let b = translate("manageNicknameDescription")

  const {
    affiliated,
    registrationAffiliated,
    authSite,
    registrationSubscriber,
  } = useStores()
  const { editProfileCreator } = affiliated
  const { validate } = registrationAffiliated
  const { updateSubscriberAnonimatta } = registrationSubscriber
  const { updateDataStorage } = authSite

  const [errors, setErrors] = useState([])
  const [userEmail, setUserEmail] = useState("")
  const [creatorName, setCreatorName] = useState("")
  const [creatorNickname, setCreatorNickname] = useState("")
  const [creatorDescription, setCreatorDescription] = useState("")
  const [loading, setLoading] = useState(false)

  const VALIDATE_MESSAGES = {
    required: requiredInput,
    types: {
      email: emailInputMessage,
      name: errorFullName,
    },
  }

  const handleBlur = (e) => {
    if (e.target.value) {
      setCompletedStyle(styles.completedInput)
    }
  }

  const handleChangeEmail = (event) => {
    const value = event.target.value
    setUserEmail(value)
  }

  const handleChangeName = (event) => {
    const value = event.target.value
    setCreatorName(value)
  }

  const handleChangeNickname = (event) => {
    const value = event.target.value
    setCreatorNickname(value)
  }

  const handleChangeDescription = (event) => {
    const value = event.target.value
    setCreatorDescription(value)
  }

  const handleEditCreator = async () => {
    setLoading(true)
    let successMsg = null
    if (isEmail) {
      data.publicEmail = userEmail
      successMsg = "emailChangeSuccess"
    }
    if (isName) {
      data.name = creatorName
      successMsg = "changeNameSuccess"
    }
    if (isNickname) {
      data.nickname = creatorNickname
      successMsg = "changeNicknameSuccess"
    }

    if (isDescription) {
      data.describe = creatorDescription
      successMsg = "changeDescriptionSuccess"
    }

    const response = await editProfileCreator(data.id, data)
    updateDataStorage(response)

    const errorMessage = _get(response, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }
    setLoading(false)
    handleClose()
    handleGetDataById()
    setSuccessMessage(translate(successMsg))
  }

  const handleEditSubscriber = async () => {
    setLoading(true)
    let successMsg = null

    if (isName) {
      data.name = creatorName
      successMsg = "changeNameSuccess"
    }
    if (isNickname) {
      data.nickname = creatorNickname
      successMsg = "changeNicknameSuccess"
    }
    if (isEmail) {
      data.email = userEmail
      successMsg = "emailChangeSuccess"
    }

    const response = await updateSubscriberAnonimatta(data.id, data)
    updateDataStorage(response)

    const errorMessage = _get(response, "message", "")

    if (errorMessage) {
      const adaptedErrors = errorsAdapter(errorMessage)
      setErrors(adaptedErrors)
      return
    }
    setLoading(false)
    handleClose()
    handleGetDataSub()
    setSuccessMessage(translate(successMsg))
  }

  const validateEmail = () => ({
    async validator(rule, value) {
      if (value) {
        const unique = await validate({ email: value })

        if (!unique) {
          return Promise.reject(
            intl.formatMessage({ id: "validateEmailUnique" })
          )
        }
      }

      return Promise.resolve()
    },
  })

  const validateFullName = () => ({
    validator(rule, value) {
      if (value) {
        const regexNameOnlyCharacters = new RegExp(
          "[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$"
        )
        const regexNameLength = new RegExp(
          /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{3,} [A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]{2,}[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ]+/
        )

        if (!regexNameOnlyCharacters.test(value)) {
          return Promise.reject(
            intl.formatMessage({ id: "errorOnlyCharacters" })
          )
        }

        if (!regexNameLength.test(value)) {
          return Promise.reject(intl.formatMessage({ id: "errorFullName" }))
        }
      }

      return Promise.resolve()
    },
  })

  return (
    <div className={cx("managePublicEmail", styles.mainContainer)}>
      <div className={styles.descriptionContainer}>
        <span className={styles.form_title}>
          {isEmail && translate("email")}
          {isName && translate("changeName")}
          {isNickname && translate("changeNickname")}
          {isDescription && translate("descriptionTitle")}
        </span>

        {isEmail && (
          <span className={styles.subtitleEmail}>
            {translate("managePublicEmailDescription")}
          </span>
        )}
        {isName && (
          <span className={styles.subtitleName}>
            {translate("manageNameDescription")}
          </span>
        )}
        {isNickname && (
          <div className={styles.subtitleNickname}>
            <span>{translate("manageNicknameDescription1")}</span>
            <span>{translate("manageNicknameDescription2")}</span>
          </div>
        )}
        {isDescription && (
          <div className={styles.subtitleNickname}>
            {translate("descriptionTitleSub")}
          </div>
        )}
      </div>

      <div className={styles.containerLabel}>
        <span className={styles.form_label}>
          {isEmail && translate("email")}
          {isName && translate("fullName")}
          {isNickname && translate("nickname")}
        </span>
      </div>
      <Form
        validateMessages={VALIDATE_MESSAGES}
        onFinish={!isSubscriber ? handleEditCreator : handleEditSubscriber}
        initialValues={{
          email: isSubscriber ? data.email : data.publicEmail,
          name: data.name,
          nickname: data.nickname,
          describe: data.describe,
        }}
      >
        {isEmail && (
          <Form.Item
            name="email"
            rules={[{ type: "email" }, validateEmail]}
            validateTrigger="onBlur"
          >
            <Input
              className={cx(styles.inputContainer, completedStyle)}
              onBlur={handleBlur}
              /*  placeholder={placeholder} */
              onChange={(event) => handleChangeEmail(event)}
            />
          </Form.Item>
        )}
        {isName && (
          <Form.Item
            name="name"
            rules={[{ required: true }, validateFullName]}
            validateTrigger="onBlur"
          >
            <Input
              className={cx(styles.inputContainer, completedStyle)}
              /*  placeholder={placeholderName} */
              onBlur={handleBlur}
              onChange={(event) => handleChangeName(event)}
            />
          </Form.Item>
        )}
        {isNickname && (
          <Form.Item name="nickname">
            <Input
              className={cx(styles.inputContainer, completedStyle)}
              /*   placeholder={placeholderNickname} */
              onBlur={handleBlur}
              onChange={(event) => handleChangeNickname(event)}
            />
          </Form.Item>
        )}
        {isDescription && (
          <Form.Item name="describe">
            <TextArea
              rows={4}
              autoSize
              showCount
              maxLength={600}
              className={completedStyle}
              onBlur={handleBlur}
              onChange={(event) => handleChangeDescription(event)}
            />
          </Form.Item>
        )}
        <Button
          type="primary"
          className={styles.button}
          htmlType="submit"
          loading={loading}
        >
          {translate("save")}
        </Button>
      </Form>

      {errors.length ? (
        <CustomModalErrors errors={errors} setErrors={setErrors} />
      ) : null}
    </div>
  )
}

export default ManagePublicEmail
