import { Icon } from "@components/Icons/index"
import { useStores } from "@hooks/use-stores"
import ForgotPassword from "@modules/MyAccount/ForgotPassword"
import translate from "@src/i18n"
import { Button, Form, Input } from "antd"
import cx from "classnames"
import React, { useState } from "react"
import { useIntl } from "react-intl"

import styles from "./style.module.less"

const ChangePhone = ({
  setModalContent,
  data,
  setVisible,
  setSuccessMessage,
  areaCode,
  isSubscriber,
}) => {
  const [validatePassword, setValidatePassword] = useState(1)
  const [completedStyle, setCompletedStyle] = useState("")
  const [password, setPassword] = useState()
  const [passwordError, setPasswordError] = useState(null)
  const [loading, setLoading] = useState(false)

  const { authSite } = useStores()

  const { changePasswordProfile } = authSite

  const [form] = Form.useForm()

  const handleBlur = (e) => {
    if (e.target.value) {
      setCompletedStyle(styles.completedInput)
    }
  }

  const handleLogin = async () => {
    setLoading(true)
    setPasswordError(null)
    const response = await changePasswordProfile({
      phoneNumber: data.phoneNumber,
      password: password,
    })

    if (response === false) {
      setPasswordError("Senha inválida")
      setValidatePassword(1)
      setLoading(false)
    } else {
      setValidatePassword(2)
      setLoading(false)
    }
  }

  return (
    <div className={cx("changePhoneContext", styles.mainContainer)}>
      {validatePassword === 1 && (
        <>
          <span className={styles.form_title}>{translate("changePhone")}</span>
          <div className={styles.descriptionContainer}>
            <span>{translate("passwordMessage")}</span>
          </div>
          <div className={styles.container}>
            <span className={cx(styles.password)}>{translate("password")}</span>
            <span className={styles.recoverLink}>
              <a
                onClick={() =>
                  setModalContent(<ForgotPassword areaCode={areaCode} />)
                }
              >
                {translate("forgotPassword")}
              </a>
            </span>
          </div>
        </>
      )}

      {validatePassword === 1 ? (
        <>
          <Form>
            <Form.Item
              help={<div>{passwordError}</div>}
              validateStatus={passwordError && "error"}
            >
              <Input.Password
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                iconRender={(visible) =>
                  visible ? <Icon name="EyeOpen" /> : <Icon name="EyeClose" />
                }
                className={completedStyle}
                onBlur={handleBlur}
              />
            </Form.Item>
          </Form>

          <Button
            className={styles.button}
            type="primary"
            onClick={handleLogin}
            loading={loading}
          >
            {translate("continue")}
          </Button>
        </>
      ) : validatePassword == 2 ? (
        <ForgotPassword
          isProfile
          isSubscriber={isSubscriber}
          setStepProfile={setValidatePassword}
          data={data}
          setSuccessMessage={setSuccessMessage}
          setVisible={setVisible}
          areaCode={areaCode}
        />
      ) : null}
    </div>
  )
}

export default ChangePhone
